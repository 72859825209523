@font-face {
  font-family: 'Boycott';
  src: url(../public/fonts/BOYCOTT_.woff);
}

.main-container{
  overflow: auto;
  width: 100%;
  max-width: none;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.content {
  padding-top: 18px;
  height: 93vh;
  min-height: 50%;
  width: 100%;
  position: relative;
}

.content p{
  white-space: pre-wrap;
  text-align: justify;  
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

img.footer-logo {
  margin-left: 20%;
}

img.image-in-text {
  width: 45%;
  float: left;
  margin-right: 12px;
}

img.click-to-open {
  cursor: pointer;
}

img.fullscreen {
  max-width: 80%;
  max-height: 80%;
  margin: 0 auto;
}

.footer h3 {
  margin: 0 0 12px 0;
}

.spotify-player {
  width: "100%";
  height: "80%";
}

.loading {
  font-family: Boycott, Verdana, Arial, sans-serif;
  font-weight: lighter;
  width: 100%; 
  height: 100%;
  text-align:center;
  padding-top:20%;
}

.loading-text{
    font-family: Boycott, Verdana, Arial, sans-serif;
    font-weight: lighter;    
}

.rotating-image {
  top: 50%;
  left: 50%;
  width: 25%;
  opacity: 0.7;
  min-width:120px;
  max-width:240px;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}

.elementToFadeInAndOut {
    -webkit-animation: fadeinout 2s linear infinite;
    animation: fadeinout 2s linear infinite;
    color: white;
    margin-top: 40px;
}

@-webkit-keyframes fadeinout {
    0%,100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0%,100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: 767px) {
  .hidden-mobile{
    display: none;
  }
}