@font-face {
    font-family: 'Ash';
    src: url(./data/DCC_Ash.woff);
}

.navbar-title,.content h2{
    font-family: Ash, Verdana, Arial, sans-serif;
    font-weight: lighter;
    font-size: 1.5rem;
}

.paper-content {
    background-image: linear-gradient(130deg, #72787e44, #2e394444 70%) !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 12px !important;
}